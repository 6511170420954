<template>
    <div>
        <!-- 顶部搜索栏 -->
        <el-row>
            <el-col :xs="24" :sm="24" :lg="24">
                <div class="pad-container flx ali-c">
                    <el-row>
                        <el-input
                                placeholder="订单编号"
                                class="search-input"
                                clearable
                                v-model="searchObj.orderId"
                        ></el-input>
                        <el-select
                                clearable
                                placeholder="选择门店"
                                class="search-input ml-15"
                                v-model="searchObj.storeId"
                                :disabled="disabled"
                                v-if="!isMembers"
                        >
                            <el-option
                                    v-for="(item, index) in store_list"
                                    :key="index"
                                    :label="item.storeName"
                                    :value="item.id"
                            ></el-option>
                        </el-select>
                        <el-select
                                v-model="searchObj.status"
                                clearable
                                placeholder="处理状态"
                                class="ml-15">
                            <el-option
                                    label="待初审"
                                    value="-1">
                            </el-option>
                            <el-option
                                    label="已同意"
                                    value="0">
                            </el-option>
                            <el-option
                                    label="已拒绝"
                                    value="1">
                            </el-option>
                        </el-select>
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                class="ml-15"
                                @click="initData"
                        >查询</el-button
                        >
                        <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
                        >重置</el-button
                        >
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-row class="mt-24">
            <el-col el-col :xs="24" :sm="24" :lg="24">
                <div class="pad-container">
                    <!-- 退款状态 -->
                    <el-tabs v-model="searchObj.auditStatus" @tab-click="changeTab">
                        <el-tab-pane label="待审核" name="0"></el-tab-pane>
                        <el-tab-pane label="已审核" name="1"></el-tab-pane>
                    </el-tabs>

                    <!-- 表格 -->
                    <el-table class="mt-24 member-table" :data="tableData">
                        <el-table-column
                                label="订单编号"
                                prop="orderId"
                                width="200"
                                fixed
                        ></el-table-column>
                        <el-table-column
                                label="达达订单编号"
                                prop="dadaOrderId"
                                width="200"
                                fixed
                        ></el-table-column>
                        <el-table-column label="门店">
                            <template slot-scope="scope">
                                {{getStoreName(scope.row.storeId)}}
                            </template>
                        </el-table-column>
                        <el-table-column label="处理状态">
                            <template slot-scope="scope">
                                {{getStatusStr(scope.row.status)}}
                            </template>
                        </el-table-column>
                        <el-table-column label="理由" prop="cancelReason"></el-table-column>
                        <el-table-column label="操作" width="300" v-if="status == -1">
                            <template slot-scope="scope">
                                <div class="flx-row ali-c">
                                    <el-button
                                        @click="confirmMessage(scope.row.id, 1)"
                                        type="text"
                                    >同意</el-button>
                                    <el-button
                                        @click="confirmMessage(scope.row.id, 0)"
                                        type="text"
                                    >拒绝</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="flx-row ali-c js-c mt-24">
                        <Pagination
                                :total="total"
                                :pageNum="pageNum"
                                :pageSize="pageSize"
                                :pageSizes="pageSizes"
                                @changePage="initData"
                                @syncPageData="syncPageData"
                        />
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import { getStoreList } from "@/api/online_department_store/commodity_manage";
    import { getDadaCancelRequestList, confirmMessage } from "@/api/transactions/order/orders_department";
    export default {
        components: { Pagination },
        data() {
            return {
                searchObj: {
                    orderId: "",
                    status: null,
                    auditStatus: null,
                    storeId: null
                },
                disabled: false,
                tableData: [],
                total: 0,
                pageNum: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 50, 100],
                statusList: [
                    {id: -1, text: "待审核"},
                    {id: 0, text: "已同意"},
                    {id: 1, text: "已拒绝"}
                ],
                store_list: []
            };
        },
        props: {
            orderTypes: {
                type: [String, Number],
                default: "",
            },
            isMembers: {
                type: Boolean,
                default: false,
            },
            memberId: {
                type: String,
                default: "",
            },
        },
        mounted() {
            this.getStores();
            this.initData();
        },
        methods: {
            reset() {
                this.searchObj =  {
                    orderId: "",
                    status: null,
                    auditStatus: null,
                    storeId: null
                }
            },
            initData() {
                if (this.$store.getters.storeId) { //门店管理员  && this.$store.getters.userObject.userRoleVos[0].type==3
                    this.searchObj.storeId = this.$store.getters.storeId;
                    this.disabled = true;
                }

                let data = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    ...this.searchObj,
                };
                data.status =
                    this.searchObj.status || this.searchObj.status == 0
                        ? this.searchObj.status
                        : "";
                getDadaCancelRequestList(data).then((res) => {
                    // // console.log("订单数据", res);
                    this.tableData = res.data.body.list;
                    this.total = res.data.body.total;
                });
            },
            changeTab(tab, event) {
                this.searchObj.auditStatus = tab.name;
                this.initData();
            },
            getStatusStr(id) {
                let text = "";
                this.statusList.forEach(item => {
                    if(id == item.id) {
                        text = item.text;
                        return
                    }
                })
                return text;
            },
            getStoreName(id) {
                let text = "";
                this.store_list.forEach(item => {
                    if(id == item.id) {
                        text = item.storeName;
                        return
                    }
                })
                return text
            },
            syncPageData(data) {
                this.pageNum = data.num;
                this.pageSize = data.size;
            },
            // 获取门店列表
            getStores() {
                getStoreList().then((res) => {
                    this.store_list = res.data.body.list;
                });
            },
            /**
             * 处理请求
             * @param id 请求id
             * @param status 0.拒绝 1.同意
             */
            confirmMessage(id, status) {
                let data = {
                    id: id,
                    isConfirm: status
                }
                confirmMessage(data).then(res => {
                    console.log(res)
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '已'+ id == 0 ? '拒绝' : '同意' + '取消订单!'
                        });
                    } else {
                        this.$message({
                            type: 'failed',
                            message: res.data.msg
                        });
                    }
                    this.initData()
                })
            }
        }
    };
</script>
<style lang="scss" scoped>
    .txt-tip-info {
        // color: #409eff;
        cursor: pointer;
    }
    .capture {
        padding: 20px;
        color: #000;
    }
    .capture div {
        margin-bottom: 5px;
    }
    .captureTable {
        border-collapse: collapse;
        border-top: solid #ccc 1px;
        border-left: solid #ccc 1px;
        width: 100%;
        margin-bottom: 5px;
    }
    .captureTable td,
    .captureTable th {
        border-right: solid #ccc 1px;
        border-bottom: solid #ccc 1px;
        text-align: center;
        height: 40px;
    }
    .hr {
        height: 1px;
        width: 100%;
        background: #999;
        margin-bottom: 5px;
        display: inline-block;
    }
    .question {
        font-size: 30px;
        color: orange;
        margin-right: 5px;
    }
    .width {
        margin-top: 10px;
    }
    .img {
        width: 160px;
        height: 160px;
        margin: 20px;
    }
    .good-wrapper {
        box-sizing: border-box;
        width: 100%;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 20%;
    }
    .red {
        color: red
    }
</style>
